<template>
  <Form
    @submit="onSave"
    :validation-schema="{
      type: 'required',
      dni: 'required',
      name: 'required',
      lastName: 'required',
      lastName2: 'required',
      email: 'email',
    }"
  >
    <div>
      <h4 class="mb-5 text-secondary">Crear cliente</h4>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Rol</label>
        <Field v-model="type" :value="type" name="type" v-slot="{ field }">
          <select
            v-bind="field"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Seleccione"
          >
            <option :value="$roles.ADMIN">Administrador</option>
          </select>
        </Field>
      </div>
      <div>
        <ErrorMessage name="type" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative">
        <label class="text-dark font-semibold">DNI</label>
        <Field v-model="dni" name="dni" v-slot="{ field }">
          <div class="relative">
            <input
              v-bind="field"
              type="text"
              class="
                relative
                py-2
                px-2
                w-full
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
              placeholder="Ingrese DNI"
            />
            <span class="absolute top-0 right-0 pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M23.1022 22.1188L18.4647 17.4813C19.1844 16.5509 19.5737 15.4134 19.5737 14.217C19.5737 12.7849 19.0147 11.442 18.004 10.4295C16.9933 9.41701 15.6469 8.85986 14.2165 8.85986C12.7862 8.85986 11.4397 9.41879 10.429 10.4295C9.41652 11.4402 8.85938 12.7849 8.85938 14.217C8.85938 15.6474 9.4183 16.9938 10.429 18.0045C11.4397 19.017 12.7844 19.5741 14.2165 19.5741C15.4129 19.5741 16.5487 19.1849 17.479 18.467L22.1165 23.1027C22.1301 23.1163 22.1463 23.1271 22.164 23.1345C22.1818 23.1418 22.2009 23.1456 22.2201 23.1456C22.2393 23.1456 22.2584 23.1418 22.2761 23.1345C22.2939 23.1271 22.3101 23.1163 22.3237 23.1027L23.1022 22.3259C23.1158 22.3123 23.1266 22.2962 23.134 22.2784C23.1414 22.2606 23.1452 22.2416 23.1452 22.2224C23.1452 22.2031 23.1414 22.1841 23.134 22.1663C23.1266 22.1485 23.1158 22.1324 23.1022 22.1188ZM17.0451 17.0456C16.2879 17.8009 15.2844 18.217 14.2165 18.217C13.1487 18.217 12.1451 17.8009 11.3879 17.0456C10.6326 16.2884 10.2165 15.2849 10.2165 14.217C10.2165 13.1491 10.6326 12.1438 11.3879 11.3884C12.1451 10.6331 13.1487 10.217 14.2165 10.217C15.2844 10.217 16.2897 10.6313 17.0451 11.3884C17.8004 12.1456 18.2165 13.1491 18.2165 14.217C18.2165 15.2849 17.8004 16.2902 17.0451 17.0456Z"
                  fill="#3D3D67"
                />
              </svg>
            </span>
          </div>
        </Field>
      </div>
      <div>
        <ErrorMessage name="dni" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Nombre</label>
        <Field v-model="name" name="name" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:shadow-outline
            "
            placeholder="Ingrese nombre"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Apellido paterno</label>
        <Field v-model="lastName" name="lastName" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese apellido paterno"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="lastName" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Apellido materno</label>
        <Field v-model="lastName2" name="lastName2" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese apellido materno"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="lastName2" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Correo electrónico</label>
        <Field v-model="email" name="email" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese correo"
          />
        </Field>
      </div>
      <ErrorMessage name="email" v-slot="{ message }">
        <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
      </ErrorMessage>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Empresa (opcional)</label>
        <Field v-model="company" name="company" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese empresa"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="company" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Teléfono (opcional)</label>
        <Field v-model="phone" name="phone" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese teléfono"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="phone" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div class="text-left pb-12">
      <button
        class="rounded bg-primary py-2 px-8 text-white font-medium flex"
        type="submit"
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Crear
      </button>
    </div>
  </Form>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const emit = defineEmits(["onComplete"]);

const store = useStore();

const { value: type } = useField("type");
const { value: dni } = useField("dni");
const { value: name } = useField("name");
const { value: lastName } = useField("lastName");
const { value: lastName2 } = useField("lastName2");
const { value: email } = useField("email");
const { value: company } = useField("company");
const { value: phone } = useField("phone");

let loadingSave = ref(false);
let responseType = ref(null);
let responseMessage = ref(null);

type.value = "Admin";

const onSave = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    await store.dispatch("createUser", {
      user: {
        ...values,
        password: "123456",
        userEntity: "User",
      },
    });

    resetForm();
    emit("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al guardar la información";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
